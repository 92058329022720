import * as React from 'react';

const FourOFourPage: React.FC = () => {
  return (
    <div>
      <p>
        Page cannot be found. Please click <a href="/">here</a> to go back
      </p>
    </div>
  );
};

export default FourOFourPage;
